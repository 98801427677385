// extracted by mini-css-extract-plugin
export var customText = "v_sd d_dv d_cs d_cg";
export var videoIframeStyle = "v_nS d_d5 d_w d_H d_by d_b1 d_R";
export var customImageWrapper = "v_rh d_cs d_cg d_Z";
export var customRow = "v_pb d_bD d_bf";
export var quoteWrapper = "v_gQ d_bC d_bP d_cs d_cg";
export var quoteBar = "v_nX d_H";
export var masonryImageWrapper = "v_n3";
export var title = "v_sf";
export var Title3Small = "v_sg G_sg G_tL G_tT";
export var Title3Normal = "v_sh G_sh G_tL G_tV";
export var Title3Large = "v_sj G_sj G_tL G_tW";