// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "D_tb d_gv d_cs";
export var heroHeaderCenter = "D_gw d_gw d_cs d_dw";
export var heroHeaderRight = "D_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "D_tc d_gr d_cw";
export var heroParagraphCenter = "D_gs d_gs d_cw d_dw";
export var heroParagraphRight = "D_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "D_td d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "D_tf d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "D_tg d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "D_th d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "D_tj d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "D_tk G_tk";
export var heroExceptionNormal = "D_tl G_tl";
export var heroExceptionLarge = "D_tm G_tm";
export var Title1Small = "D_tn G_tn G_tL G_tM";
export var Title1Normal = "D_tp G_tp G_tL G_tN";
export var Title1Large = "D_tq G_tq G_tL G_tP";
export var BodySmall = "D_tr G_tr G_tL G_t3";
export var BodyNormal = "D_ts G_ts G_tL G_t4";
export var BodyLarge = "D_tt G_tt G_tL G_t5";