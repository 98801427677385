// extracted by mini-css-extract-plugin
export var alignDiscLeft = "x_sw d_fp d_bG d_dv";
export var alignLeft = "x_rd d_fp d_bG d_dv";
export var alignDiscCenter = "x_sx d_fq d_bD d_dw";
export var alignCenter = "x_bP d_fq d_bD d_dw";
export var alignDiscRight = "x_sy d_fr d_bH d_dx";
export var alignRight = "x_rf d_fr d_bH d_dx";
export var footerContainer = "x_sz d_dW d_bW";
export var footerContainerFull = "x_sB d_dT d_bW";
export var footerHeader = "x_kf d_kf";
export var footerTextWrapper = "x_sC d_w";
export var footerDisclaimerWrapper = "x_km d_km d_ck";
export var badgeWrapper = "x_sD d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "x_sF d_bz d_bJ d_bN d_bL";
export var wide = "x_sG d_cy";
export var right = "x_sH d_bK";
export var line = "x_fk d_fl d_cv";
export var badgeDisclaimer = "x_sJ d_bC d_bP d_bJ";
export var badgeContainer = "x_sK d_bz d_bH d_bP";
export var badge = "x_sL";
export var padding = "x_sM d_c7";
export var end = "x_sN d_bH";
export var linkWrapper = "x_sP d_dB";
export var link = "x_mC d_dB";
export var colWrapper = "x_sQ d_cx";
export var consent = "x_f d_f d_bC d_bP";
export var disclaimer = "x_sR d_bz d_bJ";
export var media = "x_sS d_bx d_dy";
export var itemRight = "x_sT";
export var itemLeft = "x_sV";
export var itemCenter = "x_sW";
export var exceptionWeight = "x_sX G_vb";