// extracted by mini-css-extract-plugin
export var alignLeft = "F_rd d_fp d_bG d_dv";
export var alignCenter = "F_bP d_fq d_bD d_dw";
export var alignRight = "F_rf d_fr d_bH d_dx";
export var leftWrapper = "F_tv d_bz d_bD d_bJ";
export var wrapper = "F_tw d_w d_cc";
export var imgWrapper = "F_tx";
export var caption = "F_ty";
export var text = "F_pc d_cs";
export var instagramGuttersImage = "F_g2 d_g2 d_cv d_K d_cv";
export var instagramNoGuttersImage = "F_g1 d_g1 d_cD d_K d_cD";
export var instagramContainerFull = "F_tz d_dT d_w";
export var instagramContainer = "F_tB d_dW";
export var content = "F_tC d_cc";
export var errorContainer = "F_tD";
export var instagramImg = "F_tF d_w d_H d_dY d_bd d_0 d_8 d_7 d_4 d_9 d_bR";
export var instagramImageWrapper = "F_tG d_fg d_Z";
export var instagramBtnWrapper = "F_g9 d_g9 d_0 d_bC d_bP d_bX d_dw d_bq";
export var instagramBtnWrapperLeft = "F_tH d_d2 d_d1 d_w d_bz d_bG d_c7";
export var instagramBtnWrapperCenter = "F_tJ d_d3 d_d1 d_w d_bz d_bD d_c7";
export var instagramBtnWrapperRight = "F_tK d_d4 d_d1 d_w d_bz d_bH d_c7";